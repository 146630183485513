<template>
    <div class="_wrap">
        <div class="_auto">

            <div class="_bread">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

                    <el-breadcrumb-item>问卷调查</el-breadcrumb-item>
                </el-breadcrumb>
            </div>


            <h4></h4>
            <div class="_cont">
                <div class="_p">
                    问卷调查是场馆公共服务的基础,是了解游客需求的重要途径。为了更好的发挥中共洛阳组诞生地纪念馆的职责，更加全面详实的了解游客的需求，提高今后的服务质量，通过此次问卷调查，并根据调查数据结果提供具有指向性合理化建议，从而提高服务满意度。
                </div>


                <div class="_list">
                    <div class="_li" v-for="(item, index) in list" :key="index">
                        <div class="_title">{{ index + 1 }}、{{ item.question }}</div>
                        <div class="_rd" v-if="item.type == 0">
                            <el-radio-group v-model="item.checkList">
                                <el-radio v-for="(v, i) in item.option" :key="i" :label="i">{{ v }}</el-radio>
                            </el-radio-group>
                        </div>

                        <div class="_rd" v-else>

                            <el-checkbox-group v-model="item.checkList">
                                <el-checkbox v-for="(v, i) in item.option" :key="i" :label="i">{{ v }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>


                <div class="_submit">
                    <div class="_tij" @click="submit">提交调查</div>

                </div>
            </div>
        </div>

        <fixIcon />


        <!-- <coment ref="comentRef"></coment> -->
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { research, submitQuestion } from '../../api/getData';
export default {
    data() {
        return {
            list: [],
            form: {},
            checkList: [],
            radio: "",
        }
    },
    created() {
        this.getList()
    },
    methods: {
        submit() {
            console.log(this.list)
            let params = ''
            for (let i in this.list) {
                if (this.list[i].checkList.length > 0) {
                    params += this.list[i].checkList.join(",")
                } else {
                    params += `${this.list[i].checkList}|`

                }
            }
            console.log(params)
            submitQuestion(params).then(res => {
                console.log(res)
                if (res.code == 200) {
                    for (let i in this.list) {
                        this.list[i].checkList = []
                    }
                    return this.$message({
                        customClass: "myAlert1",
                        type: "success",
                        message: res.message
                    })
                } else {
                    return this.$message({
                        customClass: "myAlert1",
                        type: "warning",
                        message: res.message
                    })

                }
            })
        },
        getList() {
            research().then(res => {
                this.list = res.data
                for (let i in this.list) {
                    this.$set(this.list[i], 'checkList', [])
                }
                console.log(this.list)


            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
    width: 255px;
    height: 47px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

._wrap
{
    width: 100%;
    background-color: #F8F5EE;
    overflow: hidden;

    ._auto
    {
        width: 85%;
        margin: 0 auto;
        margin-top: 200px;

        h4
        {
            @include tit("../../assets/images/h4-26.png");
            margin: 0 auto;
            margin-bottom: 50px;
            width: 299px;
            height: 52px;

        }
    }
}

._cont
{

    min-height: 735px;
    background: #FFFFFF;
    padding: 65px;

    ._p
    {

        padding: 30px;
        background: rgba(255, 217, 217, 0.1);
        border: 1px solid #FFD9D9;
        margin-bottom: 50px;
    }

    ._list
    {
        ._li
        {
            border-bottom: 1px dashed #DFDEDE;

            ._rd
            {
                margin-left: 20px;
            }
        }
    }


    ._submit
    {
        display: flex;
        align-items: center;
        justify-content: center;

        >div
        {
            width: 200px;
            height: 48px;

            text-align: center;
            line-height: 48px;
            margin: 40px;
            cursor: pointer;
        }

        ._tij
        {
            color: white;
            background: #AF231C;

        }

    }

}

._title
{
    margin-top: 20px;
}

::v-deep .el-input-group__prepend
{
    color: #333;

}

::v-deep .el-radio
{
    display: block;
    margin: 20px 0;
}

::v-deep .el-checkbox
{
    display: block;
    margin: 20px 0;
}


._wrap ._auto
{
    @include respondTo('phone')
    {
        width: 95% !important;
        padding: 0;
        margin-top: 80px;

        >h4
        {
            width: 173px;
            height: 30px;
            margin-top: 20px;
            margin-bottom: 30px;
            background-image: url("../../assets/images/phone/dcb.png");
        }

        ._cont
        {
            padding: 8px;

            ._p
            {
                padding: 10px;
                margin-bottom: 20px;
            }
        }
    }
}
</style>


<style>
@media (min-width:320px) and (max-width:480px) {
    .myAlert1 {
        top: 20% !important;
    }
}
</style>

